import React from "react"
import { GoogleMap, withScriptjs, withGoogleMap, Polyline } from 'react-google-maps';

const axios = require('axios');
const decodePolyline = require('decode-google-map-polyline');

var pathCoordinates = decodePolyline("qf{bI{_pxBY[mIeMoDoF{D{Fo@kAsEgEeBwAw@q@eByAECUDWP");
console.log(pathCoordinates);

axios.get('https://maps.googleapis.com/maps/api/js?key=AIzaSyBTjuG2Ciu7X7FPTE4OEd9cG26VdzfCXlI&v=3.exp')
  .then(function (response) {
    // handle success
    console.log(response);
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })

const Map = (props) => {
  return <GoogleMap
  defaultZoom={15}
  defaultCenter={pathCoordinates[0]}
>
  {/*for creating path with the updated coordinates*/}
  <Polyline
      path={pathCoordinates}
      options={{
          strokeColor: "green",
          strokeOpacity: 1,
          strokeWeight: 10,

      }}
  />
</GoogleMap>
}

export default withScriptjs(withGoogleMap(Map));