import React from "react"
import MyMap from "../../components/myMap"

const Home = (props) => {

  return( 
  <>
  <MyMap 
  isMarkerShown={false}
  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBTjuG2Ciu7X7FPTE4OEd9cG26VdzfCXlI&v=3.exp&libraries=geometry,drawing,places"
  loadingElement={<div style={{ height: `100%` }} />}
  containerElement={<div style={{ height: `800px` }} />}
  mapElement={<div style={{ height: `100%` }} />}
   />
  </>)
}

export default Home;